import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';

function HomeAdmin() {

const [authState, setAuthState] = useState(false);
let navigate = useNavigate();

useEffect(() => {
  if (localStorage.getItem("accessToken")) {
    setAuthState(true);
  }
}, []);
useEffect(()=>{
  document.getElementById('body').style.backgroundColor = `rgb(215,128,23)`;
});
useEffect(()=>{
    document.querySelectorAll('.additionalAdminPanelToggle').forEach(function(tab) {
        tab.addEventListener('click', function() {
            this.classList.toggle('activePanel');
            document.querySelector('nav').classList.toggle('hideNav');
        });
    });
    document.querySelectorAll('.additionalAdminPanelButton').forEach(function(tab) {
      tab.addEventListener('click', function() {
          document.querySelector('nav').classList.toggle('hideNav');
    });
  });
});

const logout = () => {
  localStorage.removeItem("accessToken");
  setAuthState(false);
  navigate('/');
}

  return (
    <div className="adminPanel">
      <AuthContext.Provider value={{ authState, setAuthState }}>
        {authState && (
          <>
          <Button component={Link} to="/donate" className="adminPanelButton"> <br/> Wpłata <br></br><br/> </Button>
          
          <Button component={Link} to="/withdraw" className="adminPanelButton"> <br/>Wypłata<br></br><br/> </Button>
          
          <span className="additionalAdminPanelToggle">POZOSTAŁE OPCJE</span>
          <div className="additionalAdminPanel">
            <Button component={Link} to="/sponsor" className="additionalAdminPanelButton"> <br/>Partnerzy<br></br><br/> </Button>
            
            <Button component={Link} to="/edit-or-delete" className="additionalAdminPanelButton"> <br/>Edycja wpłat<br></br><br/>  </Button>
            
            <Button component={Link} to="/school-subject" className="additionalAdminPanelButton"> <br/>Bieżący przedmiot szkolny<br></br><br/> </Button>

            <Button component={Link} to="/edit-products" className="additionalAdminPanelButton"> <br/>Edycja produktów<br></br><br/> </Button>
            
            <Button onClick={logout} className="additionalAdminPanelButton"> <br/>Wyloguj<br></br><br/> </Button>
          </div>
          </>
        )}
      </AuthContext.Provider>
    </div>
  );
}

export default HomeAdmin;
