import React from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function ChangeSchoolSubject() {
  const wsUrl = "https://ws.scool-corner-web-api.pl";
  //const wsUrl = "http://localhost:3001";
  
  const initialValues = {
    name: ""
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Pole nie może być puste")
  });

  const onSubmit = (data) => {
    axios
      .post(`${wsUrl}/school-subjects`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        navigate("/admin");
      });
  };

  let navigate = useNavigate();

  useEffect(()=>{
    document.getElementById('body').style.backgroundColor = `rgb(215,128,23)`;
  })

  return (
    <div className="addDonationPage">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form className="formContainer">
            <label>Aktualny przedmiot szkolny: </label>
            <ErrorMessage name="name" component="span" />
            <Field
              className="formInput"
              name="name"
              placeholder=""
              type="text"
              autoComplete="off"
            />
            <button type="submit">
              Ustaw!
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangeSchoolSubject;
