import React, { useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

function AddSponsor() {
  const wsUrl = "https://ws.scool-corner-web-api.pl";
  //const wsUrl = "http://localhost:3001";

  const initialValues = {
    name: "",
    amount: "",
    type: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nazwa nie może być pusta"),
    amount: Yup.number()
      .test("is-decimal", "Maksymalnie 2 miejsca po przecinku", (value) =>
        (value + "").match(/^(\d{1,5}|\d{0,5}\.\d{1,2})$/)
      )
      .required("Kwota nie może być pusta"),
    type: Yup.string().required("Wybierz któryś z rodzajów wpłaty"),
  });

  const onSubmit = (data) => {
    axios
      .post(`${wsUrl}/donations`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        navigate("/admin");
      });
  };

  let navigate = useNavigate();

  useEffect(() => {
    document.getElementById('body').style.backgroundColor = `rgb(215,128,23)`;
  }, []);

  const UpdateNameBasedOnType = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
      if (values.type === "2") {
        setFieldValue("name", "S'COOL CORNER");
      } else if (values.type === "3") {
        setFieldValue("name", "");
      }
    }, [values.type, setFieldValue]);

    return null;
  };

  return (
    <div className="addDonationPage">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form className="formContainer">
            <UpdateNameBasedOnType />
            <label>Kto jest sponsorem: </label>
            <ErrorMessage name="type" component="span" />
            <div className="radioGroup" role="group">
              <div className="donationTypeRadioButton">
                <Field id="radio1" type="radio" name="type" value="2" />
                <label htmlFor="radio1">S'COOL-CORNER</label>
              </div>
              <div className="donationTypeRadioButton">
                <Field id="radio2" type="radio" name="type" value="3" />
                <label htmlFor="radio2">INNY SPONSOR</label>
              </div>
            </div>
            {props.values.type === "3" && (
              <fieldset className="form-group">
                <label>Nazwa sponsora: </label>
                <ErrorMessage name="name" component="span" />
                <Field
                  className="formInput"
                  name="name"
                  placeholder=""
                  type="text"
                  autoComplete="off"
                />
              </fieldset>
            )}
            <label>Kwota: </label>
            <ErrorMessage name="amount" component="span" />
            <Field
              className="formInput"
              name="amount"
              placeholder=""
              type="number"
              autoComplete="off"
            />
            <button type="submit">Wesprzyj!</button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddSponsor;
